import { isServiceWorkerAvailable } from '@/utils/is-service-worker-available.ts';
import { traceError } from '@/utils/trace-error.ts';
import mixpanel from 'mixpanel-browser';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { HydratedRouter } from 'react-router/dom';
import { APP_URL } from './env.ts';
import { handleImportError } from './handle-import-error.ts';
import { initSentry } from './sentry.client.ts';

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
  api_host: APP_URL,
  api_routes: {
    track: 'mp/t',
    engage: 'mp/e',
    groups: 'mp/g',
  },
  track_pageview: true,
  persistence: 'localStorage',
});

if (__WITH_SENTRY__) {
  initSentry();
}

if (isServiceWorkerAvailable()) {
  navigator.serviceWorker
    .register('/sw.js', { scope: '/' })
    .catch((err) =>
      traceError(
        new Error('Service Worker registration failed', { cause: err }),
      ),
    );
}

handleImportError();

startTransition(() => {
  hydrateRoot(document, <HydratedRouter />);
});
